





























import Vue from 'vue'
export default Vue.extend({
  name: 'AppFooterContact',
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    companyInfo (): Record<string, any> {
      return [
        {
          title: this.data.configuration.wb_company_address__text__,
          icon: '$marker'
        },
        {
          title: this.data.configuration.wb_company_email__text__,
          path: `mailto:${this.data.configuration.wb_company_email__text__}`,
          icon: '$mail'
        },
        {
          title: this.data.configuration.wb_company_phone__text__,
          path: `tel:${this.data.configuration.wb_company_phone__text__}`,
          icon: '$phone'
        }
      ]
    }
  }
})
